/** Libraries */
import React from "react";
import { useNavigate } from "react-router-dom";

/** Atoms */
import {
  DtkIconImage,
  ElfIconImage,
  KvaIconImage,
  LasIconImage,
  MekIconImage,
  NogIconsImage,
  OrdIconImage,
  TemporaryCardImage,
  VideoIconImage,
  XyzIconImage,
} from "../../../atom/CustomIcons/ExerciseIcons";

/** Custom hooks */
import { useMixpanel } from "../../../../hooks";

/** Assets */
import LectureDashboardCardImg from "../../../../assets/Icons/LectureDashboardCardImg.svg";

/** Styles */
import {
  DifficultyContainer,
  DifficultyFont,
  ItemsContainer,
  LectureAndProgressBox,
  LectureImage,
  LectureTitleFont,
  LinearProgressBar,
  MainContainer,
  PremiumImage,
  PremiumTitle,
  ProgressBarContainer,
  ProgressPercentageFont,
  StyledSkeleton,
  SubItemsBody,
  SubItemsContainer,
  TimeContainer,
  TimeFont,
  TimeIcon,
  TimeLabel,
  VideoFooter,
} from "./LectureDashboardCard.styles";

const LectureDashboardCard = ({
  isLoading,
  title,
  percentage,
  isPremium,
  sectionCategory,
  lecture,
  image,
  popup,
  video,
  videopopup,
  lectureVideoThumbnail,
}) => {
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const handleNavigate = () => {
    mixpanel.cardClicked("Lectures", title);
    if (isPremium) {
      navigate("/lecture/theory", {
        state: {
          sectionCategory: sectionCategory,
          lecture: lecture,
        },
      });
    } else {
      popup(true);
    }
  };

  const handleVideoPopup = () => {
    if (isPremium) {
      videopopup(lecture);
    } else {
      popup(true);
    }
  };

  const renderPremiumSignal = () => {
    return (
      <PremiumImage isPremium={isPremium}>
        <PremiumTitle>Premium</PremiumTitle>
      </PremiumImage>
    );
  };

  const renderLectureContent = (video) => {
    return (
      <SubItemsContainer>
        <SubItemsBody>
          {isPremium && (
            <DifficultyContainer isHard={lecture?.lectureLabel === "Fördjupad"}>
              <DifficultyFont isHard={lecture?.lectureLabel === "Fördjupad"}>
                {lecture?.lectureLabel}
              </DifficultyFont>
            </DifficultyContainer>
          )}
        </SubItemsBody>
        <LectureAndProgressBox video={video}>
          <LectureTitleFont className="lecture-title-font">
            {title}
          </LectureTitleFont>
          {!video ? (
            <ProgressBarContainer>
              <LinearProgressBar
                variant="determinate"
                value={Number(percentage)}
              />
              <ProgressPercentageFont>{percentage}%</ProgressPercentageFont>
            </ProgressBarContainer>
          ) : (
            <VideoFooter>
              <TimeLabel isSeen={percentage > 0}>
                {" "}
                {percentage > 0 ? "Visad" : "Se video"}{" "}
              </TimeLabel>
              <TimeContainer>
                <TimeIcon />
                <TimeFont>3 min</TimeFont>
              </TimeContainer>
            </VideoFooter>
          )}
        </LectureAndProgressBox>
      </SubItemsContainer>
    );
  };

  const renderImageIcon = () => {
    switch (image) {
      case "DTK":
        return <DtkIconImage image={lectureVideoThumbnail} />;
      case "ELF":
        return <ElfIconImage image={lectureVideoThumbnail} />;
      case "KVA":
        return <KvaIconImage image={lectureVideoThumbnail} />;
      case "LÄS":
        return <LasIconImage image={lectureVideoThumbnail} />;
      case "MEK":
        return <MekIconImage image={lectureVideoThumbnail} />;
      case "NOG":
        return <NogIconsImage image={lectureVideoThumbnail} />;
      case "ORD":
        return <OrdIconImage image={lectureVideoThumbnail} />;
      case "XYZ":
        return <XyzIconImage image={lectureVideoThumbnail} />;
      case "VIDEO":
        return <VideoIconImage />;
      default:
        return (
          <LectureImage src={LectureDashboardCardImg} alt="Lecture image" />
        );
    }
  };

  return !isLoading ? (
    !video ? (
      <MainContainer onClick={handleNavigate}>
        {renderPremiumSignal()}
        <ItemsContainer>
          {renderImageIcon()}
          {renderLectureContent()}
        </ItemsContainer>
      </MainContainer>
    ) : (
      <MainContainer onClick={handleVideoPopup}>
        {renderPremiumSignal()}
        <ItemsContainer>
          {renderImageIcon()}
          {renderLectureContent(true)}
        </ItemsContainer>
      </MainContainer>
    )
  ) : (
    <StyledSkeleton />
  );
};

export default LectureDashboardCard;
