/** Libraries */
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/** Components */
import { EndPoints, instance2 } from "../../../../service/Route";

/** Organisms */
import RattedOverblick from "../RattadOverblick/RattadOverblick";

/** Molecules */
import useWindowDimensions from "../../../../molecule/WindowDimensions/dimension";

/** Atoms */
import { SwitchIcon } from "../../../../atom/CustomIcons/SwitchIcon";

/** Assets */
import ExamResults from "../../../../../assets/Static/ExamResults.json";

/** Tools */
import { SendSentryError } from "../../../../../tools";

/** Utils */
import { appColors } from "../../../../../utils/commonService";
import { useUiStore, usePersistedStore } from "../../../../../hooks";

/** Material UI - Custom elements */
const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "90px",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  top: "-90px",
  left: 0,
  height: "180px",
  width: "100%",
  zIndex: 1000,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: "1rem",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
}));

const HeaderTitle = styled("h3")(({ theme }) => ({
  fontSize: "28px",
  marginBottom: "1rem",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down("md")]: {
    fontSize: "21px",
  },
}));

const BodyContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "845px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  borderRadius: 5,
  padding: "4rem 7rem",
  marginTop: "1rem",
  [theme.breakpoints.down("md")]: {
    padding: "1rem 3rem",
    border: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1.5rem",
  },
  [theme.breakpoints.down(400)]: {
    padding: "0rem 0.5rem",
  },
}));

const InfoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const InfoText = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  fontSize: "14px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "0.5rem",
    fontSize: "13px",
  },
}));

const MainResultContainer = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "1rem",
}));

const MainResultCard = styled(Grid)(({ theme }) => ({
  height: "95px",
  width: "290px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: "3rem",
  paddingRight: "0.5rem",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  border: `1px solid ${theme.palette.appcolors.grey3}`,
  borderRadius: "5px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "50px",
    border: "none",
    borderRadius: "0px",
    borderBottom: `2px solid ${theme.palette.appcolors.grey3}`,
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
}));

const MainResultBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between !important",
    width: "100%",
  },
}));

const MainResultNumber = styled("p")(({ theme }) => ({
  fontSize: "35px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "14px",
  },
}));

const MainResultText = styled("p")(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.appcolors.grey,
  marginLeft: "0.5rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    marginLeft: "0rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "12px",
  },
}));

const WideDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const SmallDisplay = styled("div")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontFamily: "Figtree !important",
    fontSize: "14px !important",
    padding: "10px 16px",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
    fontWeight: "600",
  },
}));

const TableCellText = styled("p")(({ theme }) => ({
  fontFamily: "Figtree !important",
  fontSize: "14px !important",
  fontWeight: "600",
  lineHeight: "1",
}));

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  display: "flex",
  boxShadow: "none",
  border: `1px solid ${theme.palette.appcolors.grey3}`,
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  backgroundImage: "none",
  [theme.breakpoints.down("md")]: {
    border: "none",
  },
}));

const TabLinks = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

const CustomTab = styled(Tab)(({ theme, tabvalue, tab }) => ({
  minWidth: "3rem",
  marginBottom: "0.75rem",
  marginRight: "5rem",
  padding: "12px 0px",
  color:
    tabvalue == tab
      ? `${theme.palette.appcolors.primaryColor} !important`
      : theme.palette.appcolors.primaryColor2,
  [theme.breakpoints.down(700)]: {
    marginRight: "4rem",
  },
  [theme.breakpoints.down(650)]: {
    marginRight: "3rem",
  },
  [theme.breakpoints.down(550)]: {
    marginRight: "2rem",
  },
  [theme.breakpoints.down(450)]: {
    marginRight: "1rem",
  },
}));

/** Don't delete these components bellow. */
const TemporaryNotificationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  background: appColors.brandBlue5,
  borderRadius: "5px",
  border: `1px solid ${appColors.brandBlue45}`,
  padding: "0.5rem",
  gap: "0.2rem",
  marginTop: "1rem",
}));

const TemporaryNotificationText = styled("p")(({ theme }) => ({
  fontSize: "13px",
  fontFamily: "Figtree",
  color: appColors.brandBlue65,
}));

const TemporaryInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: "15px",
  color: appColors.brandBlue65,
  marginTop: "2px",
}));

const Provresultat = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const [testSummary, setTestSummary] = useState();
  const [totalQuestionsOfKvantitative, setTotalQuestionsOfKvantitative] =
    useState();
  const [correctAnswersOfKvantitative, setCorrectAnswersOfKvantitative] =
    useState();
  const [totalQuestionsOfVerbal, setTotalQuestionsOfVerbal] = useState();
  const [correctAnswersOfVerbal, setCorrectAnswersOfVerbal] = useState();
  const [open, setOpen] = useState(true);
  const [helpPopup, setHelpPopup] = useState(false);
  const [participantsAverage, setParticipantsAverage] = useState(null);
  const [participantsNormalized, setParticipantsNormalized] = useState(null);
  const [season, setSeason] = useState(null);
  const { width } = useWindowDimensions();
  const [tabValueKvant, setTabValueKvant] = useState(0);
  const [tabValueVerb, setTabValueVerb] = useState(0);
  const [tabValueProv, setTabValueProv] = useState(0);
  const [isTimeDisabled, setIsTimeDisabled] = useState(
    params?.state?.isTimeDisabled
  );
  const repeatedNormPoints = testSummary
    ? testSummary.normering?.toFixed(2).replace(/\.0+$/, "") || "0"
    : "-";
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();
  const theme = useTheme();
  const {
    startReduxOverblickData,
    reduxOverblickData,
    reduxOverblickStatus,
    startReduxOverblickStatus,
  } = usePersistedStore();

  const handleOpenRattadOverblick = (row, index) => {
    startReduxOverblickData({
      quizId: row?._id,
      seasonId: row?.simuleraSeason,
      provpassOrder: params?.state?.provpassOrder,
      provpass: params?.state?.provpass?.simuleraQuizResult,
      provpassNumber: params?.state?.provpassOrder[index]
        ?.split("-")[2]
        ?.replace(/[^0-9]/g, ""),
      provpassType: quantitativeParts.includes(
        row.attempts[0].sectionCategory.title
      )
        ? "Kvantitativ"
        : "Verbal",
      examResultData: {
        quizId: params?.state?.quizId,
        seasonId: params?.state?.seasonId,
        provpassOrder: params?.state?.provpassOrder,
      },
    });

    startReduxOverblickStatus(true);
  };

  const handleTabsKvant = (e, val) => {
    setTabValueKvant(val);
  };

  const handleTabsVerb = (e, val) => {
    setTabValueVerb(val);
  };

  const handleTabsProv = (e, val) => {
    setTabValueProv(val);
  };

  const quantitativeParts = ["XYZ", "NOG", "KVA", "DTK"];

  useEffect(() => {
    if (params && params.state && params.state.quizId) {
      const URL = EndPoints.testSummaryByHistoryPage + params.state.quizId;
      console.log(URL);
      instance2
        .get(URL)
        .then((response) => {
          setIsTimeDisabled(response.data?.quizArray[0]?.isTimeDisabled);
          console.log("response1", response);
          setOpen(false);
          setTestSummary(response.data);
          setVerbalQuantitativeStates(response.data);
        })
        .catch((error) => {
          console.error("Error fetching test summary by history page:", error);
          SendSentryError(
            "TEST-SUMMARY",
            "Error fetching test summary by history page",
            {
              details: "Failed to fetch test summary by history page",
              error,
            }
          );
          console.log(error);
        });
    }
  }, [params, isTimeDisabled]);

  const setVerbalQuantitativeStates = (data) => {
    setCorrectAnswersOfKvantitative(
      data.correctQuestions_of_XYZ +
        data.correctQuestions_of_KVA +
        data.correctQuestions_of_NOG +
        data.correctQuestions_of_DTK
    );
    setTotalQuestionsOfKvantitative(
      data.totalQuestion_of_XYZ +
        data.totalQuestion_of_KVA +
        data.totalQuestion_of_NOG +
        data.totalQuestion_of_DTK
    );

    setCorrectAnswersOfVerbal(
      data.correctQuestions_of_ORD +
        data.correctQuestions_of_LAS +
        data.correctQuestions_of_MEK +
        data.correctQuestions_of_ELF
    );
    setTotalQuestionsOfVerbal(
      data.totalQuestion_of_ORD +
        data.totalQuestion_of_LAS +
        data.totalQuestion_of_MEK +
        data.totalQuestion_of_ELF
    );
  };

  useEffect(() => {
    if (params.state.seasonId) {
      const URL = `${EndPoints.getPreviousExams}/${params.state?.seasonId}`;
      instance2
        .get(URL)
        .then((response) => {
          console.log("response3", response);

          setSeason(response.data.simuleraSeason);
          const simuleraSeasonYear =
            response.data.simuleraSeason.title.split(" ")[1];
          const rawPointsExam = ExamResults.rawPoints.find((item) => {
            const examName = `${item.season} ${item.year}`;
            return (
              examName === response.data.simuleraSeason.title ||
              examName ===
                `${response.data.simuleraSeason.month} ${simuleraSeasonYear}`
            );
          });
          const normalizedPointsExam = ExamResults.normalizedPoints.find(
            (item) => {
              const examName = `${item.season} ${item.year}`;
              return (
                examName === response.data.simuleraSeason.title ||
                examName ===
                  `${response.data.simuleraSeason.month} ${simuleraSeasonYear}`
              );
            }
          );
          setParticipantsAverage(rawPointsExam);
          setParticipantsNormalized(normalizedPointsExam);
        })
        .catch((error) => {
          console.error("Error getting previous exams:", error);
          SendSentryError("EXAM", "Error getting previous exams", {
            details: "Failed to getting previous exams",
            error,
          });
          startErrorPopup();
          startErrorRedirectionTrue();
        });
    }
  }, []);

  function createSummaryData(
    rowName,
    totalCorrectAnswers,
    totalQuestions,
    averagePoints
  ) {
    return { rowName, totalCorrectAnswers, totalQuestions, averagePoints };
  }

  function createExamPartData(
    examPart,
    correctAnswers,
    totalQuestions,
    averageOtherParticipants,
    normalizedPoints
  ) {
    return {
      examPart,
      correctAnswers,
      totalQuestions,
      averageOtherParticipants,
      normalizedPoints,
    };
  }

  const kvantPartRows = [
    createExamPartData(
      "XYZ",
      testSummary?.correctQuestions_of_XYZ,
      testSummary?.totalQuestion_of_XYZ,
      participantsAverage?.XYZ
    ),
    createExamPartData(
      "KVA",
      testSummary?.correctQuestions_of_KVA,
      testSummary?.totalQuestion_of_KVA,
      participantsAverage?.KVA
    ),
    createExamPartData(
      "NOG",
      testSummary?.correctQuestions_of_NOG,
      testSummary?.totalQuestion_of_NOG,
      participantsAverage?.NOG
    ),
    createExamPartData(
      "DTK",
      testSummary?.correctQuestions_of_DTK,
      testSummary?.totalQuestion_of_DTK,
      participantsAverage?.DTK
    ),
    createExamPartData(
      "Total",
      correctAnswersOfKvantitative,
      totalQuestionsOfKvantitative,
      participantsAverage?.KVANT,
      (correctAnswersOfKvantitative &&
        totalQuestionsOfKvantitative &&
        testSummary?.normeringKvant?.toFixed(2).replace(/\.0+$/, "")) ||
        0
    ),
  ];

  const verbalPartRows = [
    createExamPartData(
      "ORD",
      testSummary?.correctQuestions_of_ORD,
      testSummary?.totalQuestion_of_ORD,
      participantsAverage?.ORD
    ),
    createExamPartData(
      "LÄS",
      testSummary?.correctQuestions_of_LAS,
      testSummary?.totalQuestion_of_LAS,
      participantsAverage?.LÄS
    ),
    createExamPartData(
      "MEK",
      testSummary?.correctQuestions_of_MEK,
      testSummary?.totalQuestion_of_MEK,
      participantsAverage?.MEK
    ),
    createExamPartData(
      "ELF",
      testSummary?.correctQuestions_of_ELF,
      testSummary?.totalQuestion_of_ELF,
      participantsAverage?.ELF
    ),
    createExamPartData(
      "Total",
      correctAnswersOfVerbal,
      totalQuestionsOfVerbal,
      participantsAverage?.VERB,
      (correctAnswersOfVerbal &&
        totalQuestionsOfVerbal &&
        testSummary?.normeringVerb?.toFixed(2).replace(/\.0+$/, "")) ||
        0
    ),
  ];

  const wholeExamRows = [
    createSummaryData(
      "Hela Provet",
      correctAnswersOfKvantitative + correctAnswersOfVerbal,
      totalQuestionsOfKvantitative + totalQuestionsOfVerbal,
      participantsAverage?.Total
    ),
  ];

  const reusedTableHead = () => {
    return (
      <TableHead>
        <TableRow sx={{ "& .MuiTableCell-root": { padding: "12px 16px" } }}>
          <TableCell>
            <TableCellText>Provdel</TableCellText>
          </TableCell>
          <TableCell align="left">
            <TableCellText>Rätta svar</TableCellText>
          </TableCell>
          <TableCell align="left">
            <TableCellText>Uppgifter</TableCellText>
          </TableCell>
          <TableCell align="left">
            <TableCellText>Medelvärde provdeltagare</TableCellText>
          </TableCell>
          <TableCell align="left">
            <TableCellText>Normerad poäng</TableCellText>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const reusedTableBody = (data, type) => {
    return (
      <TableBody>
        {data.map((row) => (
          <CustomTableRow key={row.examPart}>
            <TableCell component="th" scope="row">
              {row.examPart}
            </TableCell>
            <TableCell
              data-testid={`${type}-${row.examPart}-rätta-var`}
              align="left"
            >
              {row.correctAnswers}
            </TableCell>
            <TableCell
              data-testid={`${type}-${row.examPart}-uppgifter`}
              align="left"
            >
              {row.totalQuestions}
            </TableCell>
            <TableCell
              data-testid={`${type}-${row.examPart}-medelvärde-provdeltagare`}
              align="left"
            >
              {row.averageOtherParticipants}
            </TableCell>
            <TableCell
              data-testid={`${type}-${row.examPart}-nominerad-poäng`}
              align="left"
            >
              {row.normalizedPoints}
            </TableCell>
          </CustomTableRow>
        ))}
      </TableBody>
    );
  };

  const reusedListCard = (title, data) => {
    return (
      <MainResultBox>
        <MainResultText>{title}</MainResultText>
        <MainResultNumber>{data}</MainResultNumber>
      </MainResultBox>
    );
  };

  return (
    <>
      <HeaderContainer>
        <CloseRoundedIcon
          sx={{
            color: theme.palette.appcolors.grey,
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.appcolors.primaryColor,
              transform: "scale(1.1)",
            },
          }}
          onClick={() => navigate("/courses")}
        />
        <HeaderTitle sx={{ marginTop: "0rem" }}>Provresultat</HeaderTitle>
        <SwitchIcon />

        {/* 
        <Box onClick={() => setHelpPopup(!helpPopup)}>
          <HelpOutlineIcon
            sx={{
              color: theme.palette.appcolors.grey,
              cursor: "pointer",
              marginBottom: "1rem",
              "&:hover": {
                color: theme.palette.appcolors.primaryColor,
                transform: "scale(1.1)",
              },
            }}
          />
        </Box>
        {helpPopup && <HelpPopup />}
*/}
      </HeaderContainer>
      <MainContainer>
        <RattedOverblick
          {...reduxOverblickData}
          open={reduxOverblickStatus}
          handleClose={() => startReduxOverblickStatus(false)}
        />
        <BodyContainer>
          <InfoContainer>
            <HeaderTitle sx={{ marginTop: "0rem" }}>
              Provresultat - {season?.title} | {season?.month}
            </HeaderTitle>
            <InfoText sx={{ marginTop: "1rem" }}>
              <b>Din poäng:</b> Så kallade råpoäng, d.v.s antal rätta svar.
            </InfoText>
            <InfoText>
              <b>Din normerade poäng:</b> Ges i skalan 0,0-2,0. Baserat på ditt
              resultat jämfört med samtliga provdeltagare.
            </InfoText>
            <InfoText>
              <b>Medelvärde provdeltagare:</b> Medelvärde råpoäng bland alla
              provdeltagare det provet.
            </InfoText>
            <InfoText>
              <b>Normerat medelvärde provdeltagare:</b> Medelvärdet bland alla
              provdeltagare det provet.
            </InfoText>
          </InfoContainer>
          {/** This notification might be useful for future Exams so we should keep this here 
          {season?.title.includes("Hösten 2024") && (
            <TemporaryNotificationContainer>
              <TemporaryInfoIcon />
              <TemporaryNotificationText>
                <span style={{ fontWeight: 700, color: appColors.brandBlue65 }}>
                  Observera!
                </span>{" "}
                Dessa normerade poäng och genomsnittsvärden är preliminära
                eftersom de officiella siffrorna för hösten 2024 publiceras 27e
                November.
              </TemporaryNotificationText>
            </TemporaryNotificationContainer>
          )}
*/}
          <Backdrop
            sx={{
              color: theme.palette.appcolors.popupOverlay,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
          >
            <CircularProgress color="inherit" size="5rem" />
          </Backdrop>
          <MainResultContainer>
            <HeaderTitle>Resultat</HeaderTitle>
            <Grid
              container
              columns={{ xs: 12, md: 8 }}
              columnGap={3}
              rowGap={{ xs: 0, md: 3 }}
              justifyContent="space-around"
              alignItems="center"
            >
              <MainResultCard item>
                <WideDisplay>
                  <MainResultBox>
                    <MainResultNumber data-testid="din-poäng">
                      {testSummary?.correctQuestion || 0}
                    </MainResultNumber>
                    <MainResultText>Din poäng</MainResultText>
                  </MainResultBox>
                </WideDisplay>
                <SmallDisplay>
                  {reusedListCard(
                    "Din poäng",
                    testSummary?.correctQuestion || 0
                  )}
                </SmallDisplay>
              </MainResultCard>

              <MainResultCard item>
                <WideDisplay>
                  <MainResultBox>
                    <MainResultNumber data-testid="din-normerade-poäng">
                      {repeatedNormPoints}
                    </MainResultNumber>
                    <MainResultText>Din normerade poäng</MainResultText>
                  </MainResultBox>
                </WideDisplay>
                <SmallDisplay>
                  {reusedListCard("Din normerade poäng", repeatedNormPoints)}
                </SmallDisplay>
              </MainResultCard>

              <MainResultCard item>
                <WideDisplay>
                  <MainResultBox>
                    <MainResultNumber data-testid="medelvärde-provdeltagare">
                      {participantsAverage?.Total}
                    </MainResultNumber>
                    <MainResultText>Medelvärde provdeltagare</MainResultText>
                  </MainResultBox>
                </WideDisplay>
                <SmallDisplay>
                  {reusedListCard(
                    "Medelvärde provdeltagare",
                    participantsAverage?.Total
                  )}
                </SmallDisplay>
              </MainResultCard>

              <MainResultCard item>
                <WideDisplay>
                  <MainResultBox>
                    <MainResultNumber data-testid="normerat-medelvärde-provdeltagare">
                      {participantsNormalized?.average}
                    </MainResultNumber>
                    <MainResultText>
                      Norm. medelvärde provdeltagare
                    </MainResultText>
                  </MainResultBox>
                </WideDisplay>
                <SmallDisplay>
                  {reusedListCard(
                    "Normerat medelvärde provdeltagare",
                    participantsNormalized?.average
                  )}
                </SmallDisplay>
              </MainResultCard>
            </Grid>
          </MainResultContainer>
          <MainResultContainer>
            <HeaderTitle>Resultat för kvantitativ del</HeaderTitle>
            <WideDisplay>
              <CustomTableContainer component={Paper}>
                <Table sx={{ boxShadow: "none" }} aria-label="simple table">
                  {reusedTableHead()}
                  {reusedTableBody(kvantPartRows, "kvantitativ")}
                </Table>
              </CustomTableContainer>
            </WideDisplay>

            <SmallDisplay>
              <TabLinks>
                <Tabs
                  value={tabValueKvant}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                  onChange={handleTabsKvant}
                  TabIndicatorProps={{
                    style: {
                      border: `3.5px solid ${theme.palette.appcolors.primaryColor}`,
                      margin: "1rem 0rem",
                    },
                  }}
                >
                  <CustomTab tabvalue={tabValueKvant} tab={0} label="TOTAL" />
                  <CustomTab tabvalue={tabValueKvant} tab={1} label="KVA" />
                  <CustomTab tabvalue={tabValueKvant} tab={2} label="NOG" />
                  <CustomTab tabvalue={tabValueKvant} tab={3} label="DTK" />
                  <CustomTab tabvalue={tabValueKvant} tab={4} label="XYZ" />
                </Tabs>
              </TabLinks>
              <Grid container>
                <MainResultCard item>
                  {reusedListCard(
                    "Rätta svar",
                    tabValueKvant == 4
                      ? testSummary?.correctQuestions_of_XYZ
                      : tabValueKvant == 3
                      ? testSummary?.correctQuestions_of_DTK
                      : tabValueKvant == 2
                      ? testSummary?.correctQuestions_of_NOG
                      : tabValueKvant == 1
                      ? testSummary?.correctQuestions_of_KVA
                      : correctAnswersOfKvantitative
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Uppgifter",
                    tabValueKvant == 4
                      ? testSummary?.totalQuestion_of_XYZ
                      : tabValueKvant == 3
                      ? testSummary?.totalQuestion_of_DTK
                      : tabValueKvant == 2
                      ? testSummary?.totalQuestion_of_NOG
                      : tabValueKvant == 1
                      ? testSummary?.totalQuestion_of_KVA
                      : totalQuestionsOfKvantitative
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Medelvärde provdeltagare",
                    tabValueKvant == 4
                      ? participantsAverage?.XYZ
                      : tabValueKvant == 3
                      ? participantsAverage?.DTK
                      : tabValueKvant == 2
                      ? participantsAverage?.NOG
                      : tabValueKvant == 1
                      ? participantsAverage?.KVA
                      : participantsAverage?.KVANT
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Normerad poäng",
                    testSummary?.normeringKvant || 0
                  )}
                </MainResultCard>
              </Grid>
            </SmallDisplay>
          </MainResultContainer>
          <MainResultContainer>
            <HeaderTitle>Resultat för verbal del</HeaderTitle>
            <WideDisplay>
              <CustomTableContainer component={Paper}>
                <Table sx={{ boxShadow: "none" }} aria-label="simple table">
                  {reusedTableHead()}
                  {reusedTableBody(verbalPartRows, "verbal")}
                </Table>
              </CustomTableContainer>
            </WideDisplay>

            <SmallDisplay>
              <TabLinks>
                <Tabs
                  value={tabValueVerb}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                  onChange={handleTabsVerb}
                  TabIndicatorProps={{
                    style: {
                      border: `3.5px solid ${theme.palette.appcolors.primaryColor}`,
                      margin: "1rem 0rem",
                    },
                  }}
                >
                  <CustomTab tabvalue={tabValueVerb} tab={0} label="TOTAL" />
                  <CustomTab tabvalue={tabValueVerb} tab={1} label="ORD" />
                  <CustomTab tabvalue={tabValueVerb} tab={2} label="LÄS" />
                  <CustomTab tabvalue={tabValueVerb} tab={3} label="MEK" />
                  <CustomTab tabvalue={tabValueVerb} tab={4} label="ELF" />
                </Tabs>
              </TabLinks>
              <Grid container>
                <MainResultCard item>
                  {reusedListCard(
                    "Rätta svar",
                    tabValueVerb == 4
                      ? testSummary?.correctQuestions_of_ELF
                      : tabValueVerb == 3
                      ? testSummary?.correctQuestions_of_MEK
                      : tabValueVerb == 2
                      ? testSummary?.correctQuestions_of_LAS
                      : tabValueVerb == 1
                      ? testSummary?.correctQuestions_of_ORD
                      : correctAnswersOfVerbal
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Uppgifter",
                    tabValueVerb == 4
                      ? testSummary?.totalQuestion_of_ELF
                      : tabValueVerb == 3
                      ? testSummary?.totalQuestion_of_MEK
                      : tabValueVerb == 2
                      ? testSummary?.totalQuestion_of_LAS
                      : tabValueVerb == 1
                      ? testSummary?.totalQuestion_of_ORD
                      : totalQuestionsOfVerbal
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Medelvärde provdeltagare",
                    tabValueVerb == 4
                      ? participantsAverage?.ELF
                      : tabValueVerb == 3
                      ? participantsAverage?.MEK
                      : tabValueVerb == 2
                      ? participantsAverage?.LÄS
                      : tabValueVerb == 1
                      ? participantsAverage?.ORD
                      : participantsAverage?.VERB
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Normerad poäng",
                    testSummary?.normeringVerb || 0
                  )}
                </MainResultCard>
              </Grid>
            </SmallDisplay>
          </MainResultContainer>
          <MainResultContainer
            sx={{ marginTop: width < 900 ? "3.5rem" : "1rem" }}
          >
            <HeaderTitle>Resultat för hela provet</HeaderTitle>
            <WideDisplay>
              <CustomTableContainer component={Paper}>
                <Table sx={{ boxShadow: "none" }} aria-label="simple table">
                  {reusedTableHead()}
                  <TableBody>
                    {wholeExamRows.map((row) => (
                      <CustomTableRow
                        key={row?.rowName}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            fontWeight: "400",
                          },
                        }}
                      >
                        <TableCell scope="row">
                          {String(row?.rowName)}
                        </TableCell>
                        <TableCell
                          data-testid="total-right-answers"
                          align="left"
                        >
                          {row.totalCorrectAnswers || 0}
                        </TableCell>
                        <TableCell align="left">
                          {row.totalQuestions || 0}
                        </TableCell>
                        <TableCell align="left">
                          {row.averagePoints || 0}
                        </TableCell>
                        <TableCell align="left">{repeatedNormPoints}</TableCell>
                      </CustomTableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </WideDisplay>

            <SmallDisplay>
              <Grid container>
                <MainResultCard item>
                  {reusedListCard(
                    "Rätta svar",
                    (correctAnswersOfKvantitative || 0) +
                      (correctAnswersOfVerbal || 0)
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Uppgifter",
                    (totalQuestionsOfKvantitative || 0) +
                      (totalQuestionsOfVerbal || 0)
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard(
                    "Medelvärde provdeltagare",
                    participantsAverage?.Total || 0
                  )}
                </MainResultCard>
                <MainResultCard item>
                  {reusedListCard("Normerad poäng", repeatedNormPoints)}
                </MainResultCard>
              </Grid>
            </SmallDisplay>
          </MainResultContainer>
          <MainResultContainer>
            <HeaderTitle>Resultat för varje provpass</HeaderTitle>
            <WideDisplay>
              <CustomTableContainer component={Paper}>
                <Table sx={{ boxShadow: "none" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableCellText>Provpass</TableCellText>
                      </TableCell>
                      <TableCell align="left">
                        <TableCellText>Rätta svar</TableCellText>
                      </TableCell>
                      <TableCell align="left">
                        <TableCellText>Uppgifter</TableCellText>
                      </TableCell>
                      <TableCell align="right">
                        <TableCellText>Se frågor och svar</TableCellText>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testSummary &&
                      testSummary.quizArray.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "& .MuiTableCell-root": {
                              padding: "8px 16px",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <TableCellText sx={{ fontWeight: "600" }}>
                              {"Provpass"}{" "}
                              {params?.state?.provpassOrder[index]
                                .split("-")[2]
                                .replace(/[^0-9]/g, "")}
                            </TableCellText>
                          </TableCell>
                          <TableCell align="left">
                            <TableCellText sx={{ fontWeight: "400" }}>
                              {row.correctAnswerCounter}
                            </TableCellText>
                          </TableCell>

                          <TableCell align="left">
                            <TableCellText sx={{ fontWeight: "400" }}>
                              {row.totalQuestions}
                            </TableCellText>
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              handleOpenRattadOverblick(row, index)
                            }
                          >
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                color: theme.palette.appcolors.primaryColor,
                                border: `1px solid ${theme.palette.appcolors.primaryColor}`,
                                textTransform: "none",
                                padding: "0px 8px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Figtree",
                                  marginLeft: "0.5rem",
                                  marginRight: "0.5rem",
                                }}
                              >
                                Till provpass
                              </p>
                              <ArrowForwardIosRoundedIcon fontSize="10px" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </WideDisplay>

            <SmallDisplay>
              <TabLinks>
                <Tabs
                  value={tabValueProv}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                  onChange={handleTabsProv}
                  TabIndicatorProps={{
                    style: {
                      border: `3.5px solid ${theme.palette.appcolors.primaryColor}`,
                      margin: "1rem 0rem",
                    },
                  }}
                >
                  <CustomTab
                    tabvalue={tabValueProv}
                    tab={0}
                    label={
                      "Pass " +
                      params?.state?.provpassOrder[0]
                        .split("-")[2]
                        .replace(/[^0-9]/g, "")
                    }
                  />
                  <CustomTab
                    tabvalue={tabValueProv}
                    tab={1}
                    label={
                      "Pass " +
                      params?.state?.provpassOrder[1]
                        .split("-")[2]
                        .replace(/[^0-9]/g, "")
                    }
                  />
                  <CustomTab
                    tabvalue={tabValueProv}
                    tab={2}
                    label={
                      "Pass " +
                      params?.state?.provpassOrder[2]
                        .split("-")[2]
                        .replace(/[^0-9]/g, "")
                    }
                  />
                  <CustomTab
                    tabvalue={tabValueProv}
                    tab={3}
                    label={
                      "Pass " +
                      params?.state?.provpassOrder[3]
                        .split("-")[2]
                        .replace(/[^0-9]/g, "")
                    }
                  />
                </Tabs>
              </TabLinks>
              {testSummary &&
                testSummary.quizArray.map(
                  (row, index) =>
                    tabValueProv == index && (
                      <Grid container key={index}>
                        <MainResultCard item>
                          {reusedListCard(
                            "Rätta svar",
                            row?.correctAnswerCounter
                          )}
                        </MainResultCard>
                        <MainResultCard item>
                          {reusedListCard("Uppgifter", row?.totalQuestions)}
                        </MainResultCard>
                        <MainResultCard item>
                          <MainResultBox>
                            <MainResultText>Se frågor & svar</MainResultText>
                            <Button
                              onClick={() =>
                                handleOpenRattadOverblick(row, index)
                              }
                              style={{
                                backgroundColor: "transparent",
                                color: theme.palette.appcolors.primaryColor,
                                border: `1px solid ${theme.palette.appcolors.primaryColor}`,
                                textTransform: "none",
                                padding: "0px 8px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Figtree",
                                  marginLeft: "0.5rem",
                                  marginRight: "0.5rem",
                                }}
                              >
                                Till provpass
                              </p>
                              <ArrowForwardIosRoundedIcon fontSize="10px" />
                            </Button>
                          </MainResultBox>
                        </MainResultCard>
                      </Grid>
                    )
                )}
            </SmallDisplay>
          </MainResultContainer>
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              backgroundColor: theme.palette.appcolors.primaryColor,
              border: `1px solid ${theme.palette.appcolors.primaryColor}`,
              marginTop: "2rem",
              marginBottom: "2rem",
              color: theme.palette.appcolors.secondaryColor10,
              display: "flex",
              ":hover": {
                backgroundColor: theme.palette.appcolors.primaryColor,
                transform: "scale(1.02)",
                transition: "all 0.3s ease",
              },
            }}
            onClick={() => navigate("/courses")}
          >
            Klar
          </Button>
        </BodyContainer>
      </MainContainer>
    </>
  );
};

export default Provresultat;
