/** Libraries */
import { styled } from "@mui/material/styles";
import { Box } from "@material-ui/core";

/** Icons */
import DtkIcon from "../../../assets/Icons/DtkIcon.svg";
import ElfIcon from "../../../assets/Icons/ElfIcon.svg";
import KvaIcon from "../../../assets/Icons/KvaIcon.svg";
import LasIcon from "../../../assets/Icons/LasIcon.svg";
import MekIcon from "../../../assets/Icons/MekIcon.svg";
import NogIcons from "../../../assets/Icons/NogIcon.svg";
import OrdIcon from "../../../assets/Icons/OrdIcon.svg";
import ProvIcon from "../../../assets/Icons/ProvIcon.svg";
import XyzIcon from "../../../assets/Icons/XyzIcon.svg";
import Temporarycardicon from "../../../assets/Icons/Temporarycardicon.png";
import vidbackground from "../../../assets/Imgs/vidbackground.jpg";
import vidplayicon from "../../../assets/Icons/Vidplayicon.png";

/** Custom Hooks */
import { usePersistedStore } from "./../../../hooks";

/** Material UI - Custom elements */
const ImageContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  maxWidth: "100px",
  maxHeight: "100%",
}));

const OrangeCircle = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "-60%",
  top: "-10%",
  width: "150%",
  height: "120%",
  borderRadius: "0 50% 50% 0",
  backgroundColor: theme.palette.appcolors.tertiaryColor,
}));

const VideoBackgroundCircle = styled(Box)(({ theme, vidbg }) => ({
  position: "absolute",
  left: "-60%",
  top: "-10%",
  width: "150%",
  height: "120%",
  borderRadius: "0 50% 50% 0",
  backgroundImage: `url(${vidbg})`,
}));

const ImageIcon = styled("img")(({ theme, darkmode }) => ({
  marginLeft: "50%",
  width: "100%",
  maxWidth: "50px",
  height: "100%",
  objectFit: "contain",
  filter: darkmode && "hue-rotate(333deg)",
}));

export const DtkIconImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || DtkIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const ElfIconImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || ElfIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const KvaIconImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || KvaIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const LasIconImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || LasIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const MekIconImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || MekIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const NogIconsImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || NogIcons} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const OrdIconImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || OrdIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const XyzIconImage = ({ image }) => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={image || XyzIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const ProvIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={ProvIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const TemporaryCardImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={Temporarycardicon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const VideoIconImage = () => {
  const { darkmode } = usePersistedStore();
  return (
    <ImageContainer>
      <VideoBackgroundCircle vidbg={vidbackground}>
        <ImageIcon darkmode={darkmode} src={vidplayicon} />
      </VideoBackgroundCircle>
    </ImageContainer>
  );
};
