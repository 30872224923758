/** Libraries */
import { styled } from "@mui/material/styles";

/** Styled Components */
export const PlanBox = styled("div")(({ theme, planid }) => ({
  height: "773px",
  maxWidth: "338px",
  padding: "40px 16px",
  backgroundColor:
    planid === "planThree"
      ? theme.palette.appcolors.primaryColor18
      : "transparent",
  border:
    planid === "planThree"
      ? `2px solid ${theme.palette.appcolors.primaryColor}`
      : `2px solid ${theme.palette.appcolors.grey3}`,
  borderRadius: "8px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
  [theme.breakpoints.down(1280)]: {
    width: "100%",
    padding: "20px",
    maxWidth: "546px",
    height: "100%",
    border:
      planid === "planThree"
        ? `2px solid ${theme.palette.appcolors.primaryColor}`
        : `1px solid ${theme.palette.appcolors.grey3}`,
  },
}));

export const PopularityTab = styled("div")(({ theme, planid }) => ({
  fontSize: "16px",
  fontWeight: "600",
  position: "absolute",
  top: planid === "planThree" ? -11 : -10,
  left: "50%",
  transform: "translate(-50%, 0)",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  color: theme.palette.appcolors.primaryColor,
  padding: "2px 24px",
  borderWidth: planid === "planThree" ? 2 : 1,
  borderStyle: "solid",
  borderRadius: "20px",
  minWidth: "164px",
  zIndex: 1,
  [theme.breakpoints.down(1280)]: {
    minWidth: "0px",
    fontSize: "9px",
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "14px",
  },
}));

export const ActiveTab = styled("div")(({ theme, planid }) => ({
  position: "absolute",
  top: planid === "planThree" ? -11 : -15,
  left: "50%",
  transform: "translate(-50%, 0)",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  padding: "2px 36px",
  borderWidth: planid === "planThree" ? 2 : 1,
  borderStyle: "solid",
  border: `2px solid ${theme.palette.appcolors.success4}`,
  borderRadius: "8px",
  zIndex: 1,
  [theme.breakpoints.down(800)]: {
    top: planid === "planThree" ? -11 : -10,
    padding: "2px 24px",
  },
}));

export const ActiveLabelText = styled("p")(({ theme }) => ({
  display: "inline",
  fontSize: "16px",
  fontWeight: "600",
  textWrap: "nowrap",
  color: theme.palette.appcolors.success4,
  [theme.breakpoints.down(800)]: {
    fontSize: "14px",
  },
}));

export const DiscountLabel = styled("p")(({ theme }) => ({
  width: "100%",
  fontFamily: "Figtree",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "20.8px",
  textAlign: "center",
  color: theme.palette.appcolors.tertiaryColor11,
  height: "20px",
  position: "absolute",
  top: 60,
  left: "50%",
  transform: "translateX(-50%)",
}));

export const FullPriceLabel = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.grey,
  fontWeight: "600",
  fontSize: "18px",
  position: "absolute",
  top: 90,
  textDecoration: "none",
  "&::after": {
    content: '""',
    position: "absolute",
    left: -10,
    right: -10,
    top: "50%",
    height: "2px",
    backgroundColor: theme.palette.appcolors.grey,
  },
  [theme.breakpoints.down(1280)]: {
    top: 70,
  },
}));

export const PriceContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

export const PaySectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  height: "120px",
  position: "relative",
  paddingTop: "2.5rem",
}));

export const PayButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const ListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  maxHeight: "100%",
  margin: "auto",
  marginTop: "0px",
}));

export const RadioContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  fontSize: "12px",
  fontFamily: "Figtree",
}));

export const PriceText = styled("h2")(({ theme, premium }) => ({
  fontSize: "48px",
  fontWeight: "700",
  color: premium
    ? theme.palette.appcolors.primaryColor4
    : theme.palette.appcolors.primaryColor,
}));

export const MonthsTitle = styled("h1")(
  ({ theme, planid, gratistitle, isactivetab }) => ({
    color:
      planid === "planThree"
        ? theme.palette.appcolors.primaryColor
        : theme.palette.appcolors.black,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    height: gratistitle && "40px",
    [theme.breakpoints.down(1280)]: {
      paddingTop: (planid === "planThree" || isactivetab) && "16px",
    },
  })
);

export const PlanSubtitle = styled("h1")(({ theme, planid }) => ({
  color:
    planid === "planThree"
      ? theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.black,
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
}));

export const ManadText = styled("h1")(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  marginBottom: "-5px",
}));

export const HorizontalLine = styled("hr")(({ theme, planid }) => ({
  color:
    planid === "planThree"
      ? theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.black,
  border: "none",
  borderTop: `1px solid ${
    planid === "planThree"
      ? theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.black
  }`,
  width: "100%",
  padding: 0,
  margin: "0px 0px 30px 0px",
}));

export const PriceSubtitle = styled("p")(({ theme, toptext, bottomtext }) => ({
  position: "absolute",
  fontFamily: "Figtree",
  top: toptext && -10,
  bottom: bottomtext && 0,
  fontSize: toptext ? "12px" : "10px",
}));

export const FullPlanSwitchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  marginTop: "-4rem",
}));

export const PaymentTermText = styled("span")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "21px",
  color: theme.palette.appcolors.primaryColor,
  whiteSpace: "nowrap",
  padding: 0,
  marginTop: "-10px",
}));

export const PriceTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 0,
}));

export const ActivePlanTitle = styled("h1")(
  ({ theme, planid, gratistitle }) => ({
    fontFamily: "Poppins",
    fontSize: "18.35px",
    fontWeight: 600,
    lineHeight: "23.87px",
    textAlign: "center",
    color: theme.palette.appcolors.primaryColor,
    height: gratistitle && "40px",
  })
);
