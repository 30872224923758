/** Libraries */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/** Axios Instances */
import { EndPoints, instance2 } from "../../components/service/Route";

/** Slices */
import {
  aiTutorLogout,
  setIsRequestFailed,
  setIsRequestInProcess,
  setNewAiConversationMessage,
  setIsActive,
} from "../../redux/slices";

/** Tools */
import { SendSentryError } from "../../tools";

/** Custom hooks */
import { useMixpanel } from "../useMixpanel/useMixpanel";
import useExercisesSectionCategoriesStore from "../exercise/useExercisesSectionCategoriesStore";
import { useLocation } from "react-router-dom";

export const useAiTutorStore = () => {
  const { pathname } = useLocation();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const {
    aiConversation,
    aiMQConversation,
    isRequestInProcess,
    isRequestFailed,
    isActive,
  } = useSelector((state) => state.aiTutor);
  const currentSectionCategory = useExercisesSectionCategoriesStore();

  const [initialMessageShown, setInitialMessageShown] = useState(false);

  const startConsultingAiTutor = async ({
    message,
    isVoiceOn,
    question,
    options,
    correctOption,
    answerExplanation,
    setBuffer,
    indexMQ,
    MQDescription,
    information1,
    information2,
    model,
  }) => {
    try {
      // console.log({
      //   message,
      //   isVoiceOn,
      //   question,
      //   options,
      //   correctOption,
      //   answerExplanation,
      //   setBuffer,
      //   indexMQ,
      //   MQDescription,
      //   model,
      // });
      dispatch(setIsRequestInProcess(true));
      mixpanel.buttonClicked("AiTutor");
      if (!initialMessageShown) {
        {
          /* 
        dispatch(
          setNewAiConversationMessage({
            data: [
              {
                role: "assistant",
                content:
                  "Tack för ditt meddelande. 💌 Vänligen vänta en stund medan jag formulerar ditt svar. Det kan ta upp till en minut. 🙂",
              },
              { role: "user", content: message },
            ],
            indexMQ,
          })
        );
*/
        }
        setInitialMessageShown(true);
      } else {
        dispatch(
          setNewAiConversationMessage({
            data: [{ role: "user", content: message }],
            indexMQ,
          })
        );
      }
      dispatch(setIsRequestFailed(false));
      let messages = [];

      if (typeof indexMQ === "number") {
        messages =
          aiMQConversation[indexMQ]?.length > 1
            ? aiMQConversation[indexMQ]
            : [];
      } else {
        messages = aiConversation;
      }

      // const filteredAiCoversation = aiConversation.map(
      //   ({ buffer, ...rest }) => rest
      // );
      const URL = EndPoints.aiTutorChat;
      const { data } = await instance2.post(URL, {
        // model,
        message,
        aiConversation: messages,
        contextQuestion: {
          question: question ? question : "-",
          options,
          correctOption,
          answerExplanation: answerExplanation ? answerExplanation : "-",
          MQDescription: MQDescription ? MQDescription : "-",
          information1: information1 ? information1 : "-",
          information2: information2 ? information2 : "-",
        },
        category:
          pathname === "/lecture"
            ? `LECTURE-${currentSectionCategory?.title}`
            : currentSectionCategory?.title,
        isVoiceOn,
      });
      const { buffer: buffer } = data;
      dispatch(setIsRequestInProcess(false));
      dispatch(
        setNewAiConversationMessage({
          data: [
            {
              role: "assistant",
              content: data?.message,
              // buffer,
            },
          ],
          indexMQ,
        })
      );
      setBuffer(buffer);
    } catch (error) {
      console.log("Error: ", error);
      dispatch(setIsRequestInProcess(false));
      dispatch(setIsRequestFailed(true));
      SendSentryError("AiTutor", "Something went wrong chatting with ai", {
        details: "User sent a message to AiTutor and something failed",
        serverMessage: error,
      });
    }
  };

  const startAiTutorLogout = () => {
    dispatch(aiTutorLogout());
    switchAIKeyPress(false);
  };

  const switchAIKeyPress = (isactive) => {
    dispatch(setIsActive(isactive));
  };

  return {
    /** Values */
    aiConversation,
    aiMQConversation,
    isRequestInProcess,
    isRequestFailed,
    isActive,

    /** Functions */
    startConsultingAiTutor,
    startAiTutorLogout,
    switchAIKeyPress,
  };
};
