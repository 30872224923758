/** Libraries */
import React, { useRef, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import PricingPlan from "./PricingPlans";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
/** Organisms */
import DiscountForm from "../Discount/DiscountForm";

/** Atoms */
import { PricingReviewCards } from "../../atom/PricingReviewCards/PricingReviewCards";
import { CustomDialog } from "../HomeOrg/HomeRightBar/HomeRightBar.styles";
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { GuaranteePopup } from "../PaymentOrg/GuaranteePopup";

/** Components */
import { EndPoints, instance2 } from "../../service/Route";

/** Utils */
import { appColors } from "../../../utils/commonService";
import config from "../../../config/config";
import { update } from "../../../redux/slices";
import { SendSentryError } from "../../../tools";

/** Hooks */
import {
  useDiscountStore,
  useMixpanel,
  usePersistedStore,
  useUiStore,
} from "../../../hooks";
import AppLoader from "../../molecule/AppLoader";

require("dotenv").config();

/** Material UI - Custom elements */
const MainContainer = styled(Container)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "60px 30px",
  backgroundColor: theme.palette.appcolors.secondaryColor,
  [theme.breakpoints.down(600)]: {
    padding: "40px 20px 40px 10px",
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "1rem 0rem",
}));

const TitleText = styled("h1")(({ theme }) => ({
  fontSize: "36px",
  fontWeight: "600",
  textAlign: "center",
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
}));

const SubtitleText = styled("p")(({ theme }) => ({
  fontSize: "16px",
  maxWidth: "608px",
  textAlign: "center",
}));

const SubtitleText2 = styled("p")(({ theme }) => ({
  fontSize: "14px",
  textAlign: "center",
  fontFamily: "Figtree",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: appColors.grey35,
  maxWidth: "850px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const StripeLabel = styled("h1")(({ theme }) => ({
  paddingTop: "20px",
  paddingBottom: "20px",
  fontSize: "32px",
  fontWeight: "700",
}));

const BodyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "2rem",
}));

const Pricing = () => {
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  //set price according to plan and pass to Klarna
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  // const { darkmode } = usePersistedStore();
  const [initiateKlarna, setInitiate] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { startErrorPopup, startGuaranteePopup, guaranteePopup } = useUiStore();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const { discount } = useDiscountStore();
  const [isHalfYearly, setIsHalfYearly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const isDiscountEmpty = Object.keys(discount).length === 0;

  const { id, amount_off } = isDiscountEmpty ? {} : discount;

  useEffect(() => {
    let orderAmount = user.order_amount;
    if (orderAmount && orderAmount > 300) {
      setIsHalfYearly(true);
    }
    mixpanel.pageVisited("Checkout");
  }, []);

  const PriceIds = (plan, sectionToBuy) => {
    if (plan.id === "planTwo") {
      return config.FULL_ACCESS_PRICE_ID;
    } else if (plan.id === "planOne") {
      if (sectionToBuy === "kvantitativ") {
        return config.PARTIAL_QUANTITATIVE_PRICE_ID;
      } else {
        return config.PARTIAL_VERBAL_PRICE_ID;
      }
    } else {
      return config.ONE_TIME_FULL_PREMIUM;
    }
  };
  const goPayment = async (planDetails, sectionToBuy) => {
    setShowCheckoutForm("");
    setSelectedPlanDetails(planDetails);
    // setInitiate(true);
    // setDisableButton(true);
    const priceId = PriceIds(planDetails, sectionToBuy);
    if (!priceId) {
      return;
    }
    const payload = {
      customerId: user.customerId,
      priceId,
      isGroupOrder:
        planDetails?.isGroupOrder ||
        planDetails?.id === "planTwo" ||
        planDetails?.id === "planThree" ||
        planDetails?.id === "payingFullFromPartial",
      isDiscountEnabled: !isDiscountEmpty,
      sectionsToBuy:
        planDetails?.id === "planOne"
          ? [sectionToBuy]
          : ["kvantitativ", "verbal"],
    };

    if (!isDiscountEmpty && id) {
      payload.couponId = id;
    }

    let url = EndPoints.createCheckoutSession;
    if (user.isPremium && user.orderStatus !== "canceled") {
      url = EndPoints.changePlan;
    }

    setIsLoading(true);
    instance2
      .post(url, payload)
      .then((response) => {
        const orderId = response.data.clientSecret;
        if (orderId) {
          SendSentryError("PAYCARD", "Checkout order creating Success!", {
            details: `Checkout order creating Success!
            User Detail: name${user?.fullName}. OrderId: ${orderId}`,
          });
          dispatch(update({ order_id: orderId }));
          setShowCheckoutForm(orderId);
          scrollDown();
        } else {
          SendSentryError(
            "CHANGE PLAN",
            `Change plan Confirmation Successful for user ${response?.data?.user?.email}`,
            {
              details: `Change confirmation Success! ${response?.data?.user?.planDescription}`,
            }
          );
          console.log("response.data.user", response.data.user);

          dispatch(update(response.data.user));
          // window.location.href = config.REACT_APP_BASE_URL + `/home`;
        }
      })
      .catch((err) => {
        console.log("Checkout order creating failed");
        console.log(err);
        const message = err?.response?.data?.error?.message;
        SendSentryError("PAYCARD", "Checkout order creating failed!", {
          details: message,
        });
        startErrorPopup();
      })
      .finally(() => {
        setDisableButton(false);
        setIsLoading(false);
      });
  };

  const scrollDown = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
  };

  return (
    <MainContainer>
      <AppLoader isLoading={isLoading} />
      <CustomDialog
        open={guaranteePopup}
        onClose={() => startGuaranteePopup()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            maxWidth: "1230px !important",
            maxHeight: "908px",
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.appcolors.popupOverlay,
            scrollbarColor: `${theme.palette.appcolors.grey3} ${theme.palette.appcolors.secondaryColor2}`,
            scrollbarWidth: "thin",
          },
        }}
      >
        <StyledCloseIconRight onClick={() => startGuaranteePopup()} />
        <GuaranteePopup />
      </CustomDialog>
      <HeaderContainer>
        <TitleText>Investera i din framtid</TitleText>
        <SubtitleText>
          Premium tar dig igenom hela resan till högskoleprovet, från start till
          mål. Ta ett viktigt steg mot din drömutbildning idag.
        </SubtitleText>
        <PricingReviewCards />
      </HeaderContainer>

      <BodyContainer>
        <PricingPlan
          defaultPlan="planTwo"
          initiatePayment={initiateKlarna}
          price={selectedPlanDetails?.price}
          amount_off={amount_off || 0}
          disablePaymentButton={disableButton}
          isHalfYearly={isHalfYearly}
          goPayment={(planDetails, sectionToBuy) => {
            // if (!initiateKlarna) {
            goPayment(planDetails, sectionToBuy);
            // }
          }}
        />

        <div style={{ height: "20px" }}></div>

        {showCheckoutForm && (
          <div
            id="checkout"
            style={{
              height: "auto",
              width: "100%",
            }}
          >
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret: showCheckoutForm }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        )}

        {/* {htmlSnippet && scrollDown()} */}
        {/* {!showCheckoutForm && <DiscountForm />} */}

        {/* <div 
          style={{
            width: "100%",
            minWidth: "25rem",
            marginTop: "2rem",
          }}
          ref={checkoutContainer}
        /> */}

        <StripeLabel>{initiateKlarna ? "" : "Stripe."}</StripeLabel>

        {
          //<SubtitleText2>
          //  HP-appens abonnemang har ingen bindningstid. Observera att vald
          //  delplan (verbal eller kvantitativ) inte kan ändras, endast
          //  uppgraderas till Full Premium.
          //</SubtitleText2>
        }
      </BodyContainer>
    </MainContainer>
  );
};

export default Pricing;
