export const config = {
  REACT_APP_SERVER_NAME: "DEV",
  REACT_APP_API_BASE_URL: "https://apidev.hpappen.se",
  REACT_APP_BASE_URL: "https://dev.hpappen.se",
  STRIPE_PUBLIC_KEY:
    "pk_live_51QBJS4A2JUTGq2muu9iCTTMU97VlyRUccgMLRlwNN04WIC1Vt4tSbYmq3UnTAXfEIl1xwcqgcz0neqf61AcEZ5UJ00OlGcYh63",
  FULL_ACCESS_PRICE_ID: "price_1QI6yPA2JUTGq2mum6eo0Tbs",
  PARTIAL_QUANTITATIVE_PRICE_ID: "price_1QI6zlA2JUTGq2muzPLcsaee",
  PARTIAL_VERBAL_PRICE_ID: "price_1QI6zJA2JUTGq2muINIWY1vg",
  ONE_TIME_FULL_PREMIUM: "price_1QOFRnA2JUTGq2muc5ELEMJ0",

  // TEST
  // REACT_APP_API_BASE_URL: "http://localhost:2000",
  // REACT_APP_BASE_URL: "http://localhost:3000",
  // STRIPE_PUBLIC_KEY:
  //   "pk_test_51QBJS4A2JUTGq2mufM5R6FNQdaPLpxy5vUAabyuu3Lv084HRVsDZ6XbNYGho2X0kgstecvLfDWKGA88uDCBDA1US00aziUj9KE",
  // FULL_ACCESS_PRICE_ID: "price_1QI5XWA2JUTGq2muwzQsGOLo",
  // PARTIAL_QUANTITATIVE_PRICE_ID: "price_1QI5b7A2JUTGq2mu1If1sS3h",
  // PARTIAL_VERBAL_PRICE_ID: "price_1QI5a1A2JUTGq2mu3ZGE0X1L",
  // ONE_TIME_FULL_PREMIUM: "price_1QO2RxA2JUTGq2muJ26zttXD",

  REACT_APP_SENTRY_DSN:
    "https://d3f1d587e912423b91fda0d4d4742861@o4504945522180096.ingest.sentry.io/4504945526964224",
  REACT_APP_GOOGLE_CLIENT_ID:
    "762929285322-facjtf4j1v5g801f59hrk2corsg6t99h.apps.googleusercontent.com",
};
